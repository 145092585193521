/*
    Right:
        4px at and under 300px width
        27px at 600px width
        scales with width between 300px and 600px
        203px at 1600px width
        scales with width between 600px and 1600px
        scales upward without limit
*/
#toast-container {
    position: absolute;
    top: 0%;
    right: calc(27px + 176 * ((100vw - 600px)/1000));

    flex-direction: column;
    align-items: flex-end;

    display: flex;
}

@media(max-width: 300px) {
    #toast-container {
        right: 4px;
    }
}

@media(min-width: 300px) and (max-width: 600px) {
    #toast-container {
        right: calc(4px + 23 * ((100vw - 300px)/300));
    }
}

@media(min-width: 1600px) {
    #toast-container {
        right: calc(203px + 480 * ((100vw - 1600px)/960));
    }
}

/* similar to l-footer-panel... */
.glass-panel {
    z-index: 1;
    
    display: inline-block;

    box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
    border-top: 1px solid rgba(100, 255, 100, 0.5);
    border-left: 1px solid rgba(100, 255, 100, 0.5);
    border-radius: 1rem;
    box-sizing: border-box;
    max-width: 20vw;
    padding: 0.5rem;

    background-color: rgba(100, 223, 100, 0.15);

    text-align: center;
    word-wrap: break-word;

    backdrop-filter: blur(2px);

    cursor: pointer;
}

@media(max-width: 300px) {
    .glass-panel {
        max-width: 50vw;
    }
}

@media(min-width: 300px) and (max-width: 600px) {
    .glass-panel {
        max-width: 33vw;
    }
}

@media(min-width: 600px) and (max-width: 950px) {
    .glass-panel {
        max-width: 25vw;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes popout {
    0% {
        top: -100%;
    }
    100% {
        top: 0%;
        opacity: 1;
    }
}

.glass-panel {
    position: relative;
    opacity: 0;
    animation: popout 0.5s cubic-bezier(0, 0.79, 0, 1) forwards, fade-out 5s linear 5s forwards;
}

.glass-panel::before {
    content: '';

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    display: inline-block;
    
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    
    background-image: linear-gradient(45deg, transparent 0%, rgba(255,255,255, .07) 66%, transparent 66%);
    
    pointer-events: none;
}